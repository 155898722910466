@each $color,
$shades in $colors {

  @each $shade,
  $value in $shades {
    .text-#{$color}-#{$shade} {
      color: $value;
    }

    .bg-color-#{$color}-#{$shade} {
      background-color: $value;
    }
  }
}


.btn {
  border-radius: 12px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin: 3rem auto;
  height: 3.25rem;
  text-decoration: none;
  padding: 10px 20px;

  &.auto-w {
    width: fit-content;
  }

  &.primary {
    background-color: $primary;
    color: #FFF;
  }
}

.dona {
  background-color: rgba(#514CFF, 0.15);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  // margin: 0 auto 3.125rem;
  margin: 0 auto 2rem;
  text-decoration: none;
  padding: 1.25rem;
  text-transform: uppercase;
  color: #514CFF;
  cursor: pointer;
  position: relative;
  .text{
    width: calc(100% - 100px);
  }
  @media screen and (max-width:992px){
    padding-left: 0;
  }
  &::before {
    content: "";
    width: 69px;
    height: 54px;
    background-image: url($resolvepath+'assets/dona.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
    @media screen and (max-width:992px){
      width: 50px;
      margin-right: 10px;
    }
  }
  &.open{
    &::after {
      content: '-';}
  }
  &::after {
    content: '+';
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: #E6EFFA;
    color: #1C83E5;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
    @media screen and (max-width:992px){
      right: .5rem;
    }
  }
}

.dona-show {
  display: none;

  &.show {
    display: block;
  }

  p {
    margin-top: 0;

    a {
      color: #514CFF;
      font-weight: bold;
    }
  }
}

.btn-buy {
  margin: 20px auto 0;
  border-radius: 12px;
  border: 1px solid #7780A1;
  color: #7780A1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.15rem;
  text-decoration: none;
}

.single-block {
  margin-top: 8rem;
  position: relative;

  &:first-child {}

  &.origin {
    @media screen and (max-width:992px) {
      margin-bottom: 2rem;
    }

    &::before {
      content: "";
      width: 700px;
      height: 700px;
      background-image: url($resolvepath+'assets/lateral-dec-2.png');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -40%;
      top: 60%;
      z-index: -1;

      @media screen and (max-width:1440px) {
        left: -20%;
      }

      @media screen and (max-width:992px) {
        display: none;
      }
    }
  }

  &.vision {
    &::before {
      content: "";
      width: 700px;
      height: 700px;
      background-image: url($resolvepath+'assets/lateral-dec.png');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: -65%;
      top: -60%;
      z-index: -1;

      @media screen and (max-width:1440px) {
        right: -40%;
      }

      @media screen and (max-width:992px) {
        display: none;
      }
    }
  }

  &:last-child {
    @media screen and (max-width:992px) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width:992px) {
    margin-top: 0;
    margin-bottom: 0rem;
  }
}

.card {
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid #E2E2ED;
  -webkit-box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #FFF;

  @media screen and (max-width:992px) {
    max-width: 90%;
    margin: 0 auto;
  }

  .card-body {
    border-radius: 20px;
    overflow: hidden;
    width: fit-content;
    margin: 0 auto;

    img,
    video {
      max-height: 500px;
      display: block;
      max-width: 100%;
    }
  }

  .price-info {
    position: relative;


    // &:before {
    //   position: absolute;
    //   left: -25px;
    //   top: 0;
    //   content: "";
    //   background-image: url($resolvepath+'assets/eth.png');
    //   background-position: center;
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   width: 17px;
    //   height: 27px;
    // }
  }

  .card-info {
    display: flex;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #E2E2ED;
    align-items: center;

    .name-info {
      flex: 1;
    }

    .title {
      font-family: 'Goldman', cursive;
      font-size: 24px,
    }

    .creator {
      color: #7780A1;
      font-size: 1rem;
      margin-top: 5px;
    }
  }
}


.benefit {
  margin-top: 50px;

  @media screen and (max-width:992px) {
    margin-bottom: 50px;
  }

  .info {
    margin-top: 30px;
    padding-left: 65px;
    position: relative;

    &:before {
      content: "";
      background-color: #F7F8FA;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      background-image: url($resolvepath+'assets/star.png');
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 0;
    }

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #000;
      margin-bottom: 20px;
    }


  }
}


.desktop {
  @media screen and (max-width:660px) {
    display: none;
  }
}


.mobile {
  display: none;
  max-width: 100%;

  .hamburger {
    position: absolute;
    width: 35px;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 100;

    img {}
  }

  .close {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 2rem;
    right: 2rem;
  }

  .panel-menu {
    background-color: #FFF;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 2rem;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-100%, 0);
    transition: all .4s ease-in-out;

    &.open {
      transform: translate(0, 0);
    }

    ul {
      list-style: none;

      li {
        text-transform: uppercase;
        margin: 2.5rem 0;
        font-weight: 700;
        font-family: 'Goldman', cursive;
        font-size: 3rem;
        text-align: center;

        a {
          color: $primary;
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (max-width:660px) {
    display: block;
  }
}

//MAIL CHIMP

#mc_embed_signup {
  background: transparent;
  clear: left;
  max-width: 600px;
  margin: auto;

  input[type=button],
  .button {
    display: block;
    margin: 10px auto;
    background-color: $primary
  }

  input[type=email],
  input[type=text] {
    border-radius: 4px;
  }

  h2 {
    text-align: center;
  }

  a {
    color: $primary;
  }

  p {
    font-size: 12px;
    margin: 5px 0;
  }

  .mc-field-group {
    border: 0;
    min-height: auto;
    padding-bottom: 0;

    label {
      display: flex;
      margin: 10px 0;

      span {
        font-size: 12px;
      }

      input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }
}

.price-info span {
  background-color: rgba(#514CFF, 0.15);
  color: #2A27C9;
  font-size: 1rem;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.no-mobile {
  @media screen and (max-width:660px) {
    display: none;
  }
}


.logo-mobile {
  padding: 1.5rem 0;

  img {
    max-width: 200px;
  }
}
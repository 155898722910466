* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  font-family: 'Montserrat';

  // overflow-x: hidden;
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }

  @media screen and (max-width: 992px) {
    font-size: 12px;
  }
}

html {
  @media screen and (max-width: 992px) {
    overflow-x: hidden;
  }
}

body {
  overflow-x: hidden;
}
.app{
  width: 100vw;
  max-width: 100%;
}
.overflow {
  overflow: hidden;
}

img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

p {
  font-size: 1.125rem;
  color: #7780A1;
  margin-bottom: 15px;
  line-height: 1.55;

  strong {
    font-weight: bold;
  }
}

.txt-center {
  text-align: center;
}

header {
  position: relative;

  @media screen and (max-width:660px) {
    height: 100vh;
    background-image: url($resolvepath+'assets/Mountainzzz.jpg');
    background-size: cover;
    background-position: right;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    .btn.primary {
      @media screen and (max-width:600px) {
        margin: 1.5rem auto;
        // background-color: transparent;
      }
    }

    h1 {
      font-size: 120px;
      width: 100%;
      letter-spacing: .2px;
      line-height: .9;
      color: #FFF;
      text-shadow: 0px 0px 5px rgb(0 0 0 / 25%);

      @media screen and (max-width:1550px) {
        font-size: 100px;
      }

      @media screen and (max-width:992px) {
        font-size: 30px;
      }

      @media screen and (max-width:600px) {
        font-size: 3.5rem;
        margin-top: 1.5rem;
      }
    }

    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #FFF;
      letter-spacing: 3px;
      margin-top: 16px;

      @media screen and (max-width:600px) {
        font-size: 1.5rem;
        padding: 0 3rem;
      }
    }
  }
}

nav {
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translate(-50%, 0);

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;


    li {
      flex: 1;
      padding: 0 1rem;
      font-family: 'Goldman', cursive;
      font-size: 20px;
      text-transform: uppercase;

      a {
        color: #FFF;
        text-decoration: none;
      }

      &.logo {
        img {
          max-width: none;
          width: 200px;
        }
      }
    }
  }
}

.ntf-section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media screen and (max-width:600px) {
    padding-top: 2rem;
  }

  // overflow-x: ;
  &::after {
    content: "";
    background-image: url($resolvepath+'assets/bg-top-before.jpg');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: -40%;
    width: 100%;
    height: 1260px;
    z-index: -1;

    @media screen and (max-width:1660px) {
      bottom: -70%;
      height: 1200px;
    }

    @media screen and (max-width:13660px) {
      bottom: -90%;
      height: 1200px;
    }

    @media screen and (max-width:992px) {
      bottom: -75%;
    }
  }

  @media screen and (max-width:992px) {
    padding-bottom: 0;
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url($resolvepath+'assets/robot_anna.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    right: -45%;
    top: 4rem;

    @media screen and (max-width:992px) {
      display: none;
    }
  }
}

//COLLECTION SECTION

.collection {
  background-color: #E9F7FE;
  padding: 60px 0;
  margin-top: 6rem;
  padding-bottom: 250px;
  position: relative;

  @media screen and (max-width:600px) {
    margin-top: 3rem;
  }

  &::before {
    content: "";
    width: 700px;
    height: 700px;
    background-image: url($resolvepath+'assets/top-dec.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: -30%;
    z-index: -1;

    @media screen and (max-width:992px) {
      // display: none;
    }
  }

  h4 {
    color: #243588;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  }

  .wrapper-benefit {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 25px;
    border-top: 1px solid #FFF;
  }

  .benefit {
    margin-top: 0;

    .info {
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.list-nft {
  margin-top: -200px;
  margin-bottom: 50px;

  @media screen and (max-width:992px) {
    margin-top: -250px;
  }

  .single-nft {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//EVENT
.event {
  background-color: #E9F7FE;
  padding: 60px 0;

  .single-event {
    background-color: #FFF;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 50px;
    }

    .event-description-wrapper {
      flex: 0 0 55%;
      padding: 0 20px;

      @media screen and (max-width:660px) {
        flex: 0 0 100%;
        padding: 20px 0;
      }

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #3FC0F0;
      }

      .description {
        font-size: 14px;
        color: rgba($color: #333333, $alpha: .5)
      }
    }

    .link-wrapper {
      flex: 0 0 15%;

      @media screen and (max-width:660px) {
        flex: 0 0 100%;
      }

      .btn {
        width: 100%;
        margin: 0;
      }
    }

    .data-wrapper-info {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 30%;
      align-items: flex-start;
      border-right: 1px solid #E2E2ED;

      @media screen and (max-width:660px) {
        flex: 0 0 100%;
        border-right: 0;
      }

      .wrapper-date-details {
        padding: 0 20px;

        @media screen and (max-width:992px) {
          padding: 0 0 0 20px;
        }
      }

      .month-year {
        font-weight: bold;
        font-size: 24px;
      }

      .day-time {
        font-size: 16px;
      }

      .number {
        font-weight: bold;
        font-size: 48px;
        color: #3FC0F0;
        line-height: 1;
      }
    }
  }
}

//metaverse
.metaverse {
  padding: 3.75rem 0;
  background-image: url($resolvepath+'assets/metaverse-bg.jpg');
  background-size: cover;
  background-position: center;

  h2 {
    @media screen and (max-width:600px) {
      font-size: 2rem;
    }

    span {
      color: #FFF;
    }
  }

  p {
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
    margin-top: 30px;
  }
}

//faq

.faq {
  padding-top: 5rem;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 25rem;
    background-image: url($resolvepath+'assets/faq-bg-before.jpg');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    @media screen and (max-width:992px) {
      // display: none;
    }
  }

  .single-faq {
    border-bottom: 1px solid #E2E2ED;
    padding: 20px 0;
    width: 80%;
    margin: 0 auto;

    @media screen and (max-width:992px) {
      width: 90%;
    }

    &.open {
      .answer {
        height: auto;
        padding: 1rem 0;
        line-height: 1.55;
      }

      .question {
        &::after {
          content: '-';
        }
      }
    }

    .question {
      font-weight: 700;
      font-size: 1.5rem;
      cursor: pointer;
      padding-right: 50px;
      position: relative;

      &::after {
        content: '+';
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background-color: #E6EFFA;
        color: #1C83E5;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .answer {
      font-size: 1.125rem;
      color: #838383;
      padding: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

footer {
  padding: 20px 0;
  background-color: #E9F7FE;
  margin-top: 50px;

  .logo {
    max-width: 150px;
    float: right;

    @media screen and (max-width:992px) {
      float: none;
      margin: 0 auto;
      display: block;
    }
  }

  ul {
    list-style: none;
    font-size: 12px;
    display: flex;

    @media screen and (max-width:992px) {
      margin: 0 auto;
      justify-content: center;
    }

    li {
      margin: 10px 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: #1C83E5;
      text-decoration: none;
    }
  }

  .info-aziendali {
    font-size: 12px;
    line-height: 1.5;
    color: #838383;

    @media screen and (max-width:992px) {
      text-align: center;
    }

    a {
      color: #1C83E5;
      text-decoration: none;
    }
  }
}
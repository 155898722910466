@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@700&family=Montserrat:wght@400;500;600&display=swap");
h1,
h2,
h3,
h4,
h5 {
  font-family: "Goldman", cursive;
}

h3 {
  color: #BBC0D0;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 3px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

h2 {
  color: #000;
  font-size: 4rem;
  line-height: 0.8;
}
h2 span {
  color: #3DC0F0;
}

.btn {
  border-radius: 12px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin: 3rem auto;
  height: 3.25rem;
  text-decoration: none;
  padding: 10px 20px;
}
.btn.auto-w {
  width: fit-content;
}
.btn.primary {
  background-color: #3DC0F0;
  color: #FFF;
}

.dona {
  background-color: rgba(81, 76, 255, 0.15);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin: 0 auto 2rem;
  text-decoration: none;
  padding: 1.25rem;
  text-transform: uppercase;
  color: #514CFF;
  cursor: pointer;
  position: relative;
}
.dona .text {
  width: calc(100% - 100px);
}
@media screen and (max-width: 992px) {
  .dona {
    padding-left: 0;
  }
}
.dona::before {
  content: "";
  width: 69px;
  height: 54px;
  background-image: url("~assets/dona.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 20px;
}
@media screen and (max-width: 992px) {
  .dona::before {
    width: 50px;
    margin-right: 10px;
  }
}
.dona.open::after {
  content: "-";
}
.dona::after {
  content: "+";
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #E6EFFA;
  color: #1C83E5;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
}
@media screen and (max-width: 992px) {
  .dona::after {
    right: 0.5rem;
  }
}

.dona-show {
  display: none;
}
.dona-show.show {
  display: block;
}
.dona-show p {
  margin-top: 0;
}
.dona-show p a {
  color: #514CFF;
  font-weight: bold;
}

.btn-buy {
  margin: 20px auto 0;
  border-radius: 12px;
  border: 1px solid #7780A1;
  color: #7780A1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.15rem;
  text-decoration: none;
}

.single-block {
  margin-top: 8rem;
  position: relative;
}
@media screen and (max-width: 992px) {
  .single-block.origin {
    margin-bottom: 2rem;
  }
}
.single-block.origin::before {
  content: "";
  width: 700px;
  height: 700px;
  background-image: url("~assets/lateral-dec-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: -40%;
  top: 60%;
  z-index: -1;
}
@media screen and (max-width: 1440px) {
  .single-block.origin::before {
    left: -20%;
  }
}
@media screen and (max-width: 992px) {
  .single-block.origin::before {
    display: none;
  }
}
.single-block.vision::before {
  content: "";
  width: 700px;
  height: 700px;
  background-image: url("~assets/lateral-dec.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: -65%;
  top: -60%;
  z-index: -1;
}
@media screen and (max-width: 1440px) {
  .single-block.vision::before {
    right: -40%;
  }
}
@media screen and (max-width: 992px) {
  .single-block.vision::before {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .single-block:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 992px) {
  .single-block {
    margin-top: 0;
    margin-bottom: 0rem;
  }
}

.card {
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid #E2E2ED;
  -webkit-box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
}
@media screen and (max-width: 992px) {
  .card {
    max-width: 90%;
    margin: 0 auto;
  }
}
.card .card-body {
  border-radius: 20px;
  overflow: hidden;
  width: fit-content;
  margin: 0 auto;
}
.card .card-body img,
.card .card-body video {
  max-height: 500px;
  display: block;
  max-width: 100%;
}
.card .price-info {
  position: relative;
}
.card .card-info {
  display: flex;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #E2E2ED;
  align-items: center;
}
.card .card-info .name-info {
  flex: 1;
}
.card .card-info .title {
  font-family: "Goldman", cursive;
  font-size: 24px;
}
.card .card-info .creator {
  color: #7780A1;
  font-size: 1rem;
  margin-top: 5px;
}

.benefit {
  margin-top: 50px;
}
@media screen and (max-width: 992px) {
  .benefit {
    margin-bottom: 50px;
  }
}
.benefit .info {
  margin-top: 30px;
  padding-left: 65px;
  position: relative;
}
.benefit .info:before {
  content: "";
  background-color: #F7F8FA;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  background-image: url("~assets/star.png");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}
.benefit .info .title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

@media screen and (max-width: 660px) {
  .desktop {
    display: none;
  }
}

.mobile {
  display: none;
  max-width: 100%;
}
.mobile .hamburger {
  position: absolute;
  width: 35px;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 100;
}
.mobile .close {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 2rem;
  right: 2rem;
}
.mobile .panel-menu {
  background-color: #FFF;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 2rem;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-100%, 0);
  transition: all 0.4s ease-in-out;
}
.mobile .panel-menu.open {
  transform: translate(0, 0);
}
.mobile .panel-menu ul {
  list-style: none;
}
.mobile .panel-menu ul li {
  text-transform: uppercase;
  margin: 2.5rem 0;
  font-weight: 700;
  font-family: "Goldman", cursive;
  font-size: 3rem;
  text-align: center;
}
.mobile .panel-menu ul li a {
  color: #3DC0F0;
  text-decoration: none;
}
@media screen and (max-width: 660px) {
  .mobile {
    display: block;
  }
}

#mc_embed_signup {
  background: transparent;
  clear: left;
  max-width: 600px;
  margin: auto;
}
#mc_embed_signup input[type=button],
#mc_embed_signup .button {
  display: block;
  margin: 10px auto;
  background-color: #3DC0F0;
}
#mc_embed_signup input[type=email],
#mc_embed_signup input[type=text] {
  border-radius: 4px;
}
#mc_embed_signup h2 {
  text-align: center;
}
#mc_embed_signup a {
  color: #3DC0F0;
}
#mc_embed_signup p {
  font-size: 12px;
  margin: 5px 0;
}
#mc_embed_signup .mc-field-group {
  border: 0;
  min-height: auto;
  padding-bottom: 0;
}
#mc_embed_signup .mc-field-group label {
  display: flex;
  margin: 10px 0;
}
#mc_embed_signup .mc-field-group label span {
  font-size: 12px;
}
#mc_embed_signup .mc-field-group label input {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.price-info span {
  background-color: rgba(81, 76, 255, 0.15);
  color: #2A27C9;
  font-size: 1rem;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  position: relative;
  justify-content: flex-end;
}

@media screen and (max-width: 660px) {
  .no-mobile {
    display: none;
  }
}

.logo-mobile {
  padding: 1.5rem 0;
}
.logo-mobile img {
  max-width: 200px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  font-family: "Montserrat";
}
@media screen and (max-width: 1366px) {
  html,
body {
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  html,
body {
    font-size: 12px;
  }
}

@media screen and (max-width: 992px) {
  html {
    overflow-x: hidden;
  }
}

body {
  overflow-x: hidden;
}

.app {
  width: 100vw;
  max-width: 100%;
}

.overflow {
  overflow: hidden;
}

img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

p {
  font-size: 1.125rem;
  color: #7780A1;
  margin-bottom: 15px;
  line-height: 1.55;
}
p strong {
  font-weight: bold;
}

.txt-center {
  text-align: center;
}

header {
  position: relative;
}
@media screen and (max-width: 660px) {
  header {
    height: 100vh;
    background-image: url("~assets/Mountainzzz.jpg");
    background-size: cover;
    background-position: right;
  }
}
header img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
header .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 600px) {
  header .content .btn.primary {
    margin: 1.5rem auto;
  }
}
header .content h1 {
  font-size: 120px;
  width: 100%;
  letter-spacing: 0.2px;
  line-height: 0.9;
  color: #FFF;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 1550px) {
  header .content h1 {
    font-size: 100px;
  }
}
@media screen and (max-width: 992px) {
  header .content h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  header .content h1 {
    font-size: 3.5rem;
    margin-top: 1.5rem;
  }
}
header .content h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFF;
  letter-spacing: 3px;
  margin-top: 16px;
}
@media screen and (max-width: 600px) {
  header .content h2 {
    font-size: 1.5rem;
    padding: 0 3rem;
  }
}

nav {
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translate(-50%, 0);
}
nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav ul li {
  flex: 1;
  padding: 0 1rem;
  font-family: "Goldman", cursive;
  font-size: 20px;
  text-transform: uppercase;
}
nav ul li a {
  color: #FFF;
  text-decoration: none;
}
nav ul li.logo img {
  max-width: none;
  width: 200px;
}

.ntf-section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media screen and (max-width: 600px) {
  .ntf-section {
    padding-top: 2rem;
  }
}
.ntf-section::after {
  content: "";
  background-image: url("~assets/bg-top-before.jpg");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: -40%;
  width: 100%;
  height: 1260px;
  z-index: -1;
}
@media screen and (max-width: 1660px) {
  .ntf-section::after {
    bottom: -70%;
    height: 1200px;
  }
}
@media screen and (max-width: 13660px) {
  .ntf-section::after {
    bottom: -90%;
    height: 1200px;
  }
}
@media screen and (max-width: 992px) {
  .ntf-section::after {
    bottom: -75%;
  }
}
@media screen and (max-width: 992px) {
  .ntf-section {
    padding-bottom: 0;
  }
}
.ntf-section::before {
  position: absolute;
  content: "";
  background-image: url("~assets/robot_anna.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  right: -45%;
  top: 4rem;
}
@media screen and (max-width: 992px) {
  .ntf-section::before {
    display: none;
  }
}

.collection {
  background-color: #E9F7FE;
  padding: 60px 0;
  margin-top: 6rem;
  padding-bottom: 250px;
  position: relative;
}
@media screen and (max-width: 600px) {
  .collection {
    margin-top: 3rem;
  }
}
.collection::before {
  content: "";
  width: 700px;
  height: 700px;
  background-image: url("~assets/top-dec.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: -30%;
  z-index: -1;
}
.collection h4 {
  color: #243588;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.collection .wrapper-benefit {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 25px;
  border-top: 1px solid #FFF;
}
.collection .benefit {
  margin-top: 0;
}
.collection .benefit .info {
  padding-top: 0;
  margin-top: 0;
}

.list-nft {
  margin-top: -200px;
  margin-bottom: 50px;
}
@media screen and (max-width: 992px) {
  .list-nft {
    margin-top: -250px;
  }
}
.list-nft .single-nft {
  margin-bottom: 50px;
}
.list-nft .single-nft:last-child {
  margin-bottom: 0;
}

.event {
  background-color: #E9F7FE;
  padding: 60px 0;
}
.event .single-event {
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}
.event .single-event:first-child {
  margin-top: 50px;
}
.event .single-event .event-description-wrapper {
  flex: 0 0 55%;
  padding: 0 20px;
}
@media screen and (max-width: 660px) {
  .event .single-event .event-description-wrapper {
    flex: 0 0 100%;
    padding: 20px 0;
  }
}
.event .single-event .event-description-wrapper .title {
  font-size: 24px;
  font-weight: bold;
  color: #3FC0F0;
}
.event .single-event .event-description-wrapper .description {
  font-size: 14px;
  color: rgba(51, 51, 51, 0.5);
}
.event .single-event .link-wrapper {
  flex: 0 0 15%;
}
@media screen and (max-width: 660px) {
  .event .single-event .link-wrapper {
    flex: 0 0 100%;
  }
}
.event .single-event .link-wrapper .btn {
  width: 100%;
  margin: 0;
}
.event .single-event .data-wrapper-info {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 30%;
  align-items: flex-start;
  border-right: 1px solid #E2E2ED;
}
@media screen and (max-width: 660px) {
  .event .single-event .data-wrapper-info {
    flex: 0 0 100%;
    border-right: 0;
  }
}
.event .single-event .data-wrapper-info .wrapper-date-details {
  padding: 0 20px;
}
@media screen and (max-width: 992px) {
  .event .single-event .data-wrapper-info .wrapper-date-details {
    padding: 0 0 0 20px;
  }
}
.event .single-event .data-wrapper-info .month-year {
  font-weight: bold;
  font-size: 24px;
}
.event .single-event .data-wrapper-info .day-time {
  font-size: 16px;
}
.event .single-event .data-wrapper-info .number {
  font-weight: bold;
  font-size: 48px;
  color: #3FC0F0;
  line-height: 1;
}

.metaverse {
  padding: 3.75rem 0;
  background-image: url("~assets/metaverse-bg.jpg");
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 600px) {
  .metaverse h2 {
    font-size: 2rem;
  }
}
.metaverse h2 span {
  color: #FFF;
}
.metaverse p {
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  margin-top: 30px;
}

.faq {
  padding-top: 5rem;
  position: relative;
}
.faq:before {
  content: "";
  width: 100%;
  height: 25rem;
  background-image: url("~assets/faq-bg-before.jpg");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.faq .single-faq {
  border-bottom: 1px solid #E2E2ED;
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .faq .single-faq {
    width: 90%;
  }
}
.faq .single-faq.open .answer {
  height: auto;
  padding: 1rem 0;
  line-height: 1.55;
}
.faq .single-faq.open .question::after {
  content: "-";
}
.faq .single-faq .question {
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;
  padding-right: 50px;
  position: relative;
}
.faq .single-faq .question::after {
  content: "+";
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #E6EFFA;
  color: #1C83E5;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
.faq .single-faq .answer {
  font-size: 1.125rem;
  color: #838383;
  padding: 0;
  height: 0;
  overflow: hidden;
}

footer {
  padding: 20px 0;
  background-color: #E9F7FE;
  margin-top: 50px;
}
footer .logo {
  max-width: 150px;
  float: right;
}
@media screen and (max-width: 992px) {
  footer .logo {
    float: none;
    margin: 0 auto;
    display: block;
  }
}
footer ul {
  list-style: none;
  font-size: 12px;
  display: flex;
}
@media screen and (max-width: 992px) {
  footer ul {
    margin: 0 auto;
    justify-content: center;
  }
}
footer ul li {
  margin: 10px 5px;
}
footer ul li:first-child {
  margin-left: 0;
}
footer ul a {
  color: #1C83E5;
  text-decoration: none;
}
footer .info-aziendali {
  font-size: 12px;
  line-height: 1.5;
  color: #838383;
}
@media screen and (max-width: 992px) {
  footer .info-aziendali {
    text-align: center;
  }
}
footer .info-aziendali a {
  color: #1C83E5;
  text-decoration: none;
}
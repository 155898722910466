@import url('https://fonts.googleapis.com/css2?family=Goldman:wght@700&family=Montserrat:wght@400;500;600&display=swap');


// font-family: 'Goldman', cursive;
// font-family: 'Montserrat', sans-serif;

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Goldman', cursive;
}

h3 {
    color: #BBC0D0;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

h2 {
    color: #000;
    font-size: 4rem;
    line-height: .8;

    span {
        color: $primary;
    }
}